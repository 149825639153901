
import {
  defineComponent, computed, PropType, ref,
} from '@vue/composition-api';
import { IUpsellsProperties } from '~/types/fightcamp';
import Upsell from './Upsell.vue';
import PdpSectionHeader from './PdpSectionHeader.vue';

export default defineComponent({
  name: 'PackageUpsells',
  emits: ['selectedUpsells'],
  components: {
    Upsell,
    PdpSectionHeader,
  },
  props: {
    upsellProducts: {
      type: Object as PropType<IUpsellsProperties>,
      required: true,
    },
  },
  setup(props, { emit }) {
    // TODO: refactor or move some data to Storyblok for easier updates or move to transformers file
    const mainGloves = computed(() => props.upsellProducts?.gloveUpsells?.find((glove) => glove.variants.some((variant) => variant.uid === 'customGloves_V2_S')));
    const kidsGloves = computed(() => props.upsellProducts?.gloveUpsells?.filter((glove) => glove.variants.some((variant) => variant.uid === 'customGloves_V2_K'))[0]);
    const gloveObj = computed(() => ((!mainGloves?.value && !kidsGloves?.value) ? null : {
      ...mainGloves?.value,
      productName: mainGloves?.value?.productName || '',
      id: mainGloves?.value?.id || '',
      shortDescription: '100% handmade, ultra comfortable & super durable. Choose from 3 sizes.',
      variants: [
        ...mainGloves?.value?.variants?.map((glove) => ({
          name: glove?.name || '',
          description: glove?.uid === 'customGloves_V2_S' ? 'Better for Beginners' : 'More padding & weight for heavy hitters',
          price: mainGloves?.value?.price,
          id: glove.id,
          img: {
            src: mainGloves?.value?.primaryImg?.src,
            alt: mainGloves?.value?.primaryImg?.alt,
          },
        })) || [],
        {
          name: kidsGloves?.value?.productName,
          description: 'Perfect for kids aged 3-12',
          price: kidsGloves?.value?.price,
          id: kidsGloves?.value?.variants?.[0]?.id,
          img: {
            src: kidsGloves?.value?.primaryImg?.src,
            alt: kidsGloves?.value?.primaryImg?.alt,
          },
        },
      ],
    }));
    const upsells = computed(() => (gloveObj.value ? [gloveObj.value, ...props.upsellProducts.upsells] : null));

    const selectedUpsellItems = ref<Record<string, any>>({});

    function handleSelectedItems({ items, productId }: { items: any, productId: string }) {
      selectedUpsellItems.value[productId] = items;

      const combinedArray = Object.values(selectedUpsellItems.value).flat();
      emit('selectedUpsells', combinedArray);
    }

    return {
      handleSelectedItems,
      upsells,
    };
  },
});
