
import {
  defineComponent, inject, ref, watch, onMounted, Ref, computed,
} from '@vue/composition-api';
import { useCartContext } from '~/composables/useCartContext';
import { IPackageProperties, ISelectedUpsellProperties } from '~/types/fightcamp';
import { FiveStars, FCButton } from '@fc/angie-ui';
import SplitView from '~/components/SplitView.vue';
import FCImage from '~/components/FCImage.vue';
import storeMutations from '~/store/constants/storeMutations';
import Video from '~/components/storyblok/Video/Video.vue';
import { PACKAGE_DATA } from '~/constants/storyblok';
import { useAffirm } from '~/composables/useAffirm';
import TrackerPDPCarousel from './TrackerPDPCarousel.vue';
import AffirmPackagePrice from './AffirmPackagePrice.vue';
import PackageIncludedCarousel from './PackageIncludedCarousel.vue';
import QuickwrapSelectors from './QuickwrapSelectors.vue';
import PackageUpsells from './PackageUpsells.vue';
import PackageWarranties from './PackageWarranties.vue';

export default defineComponent({
  name: 'TrackersATF',
  components: {
    FiveStars,
    SplitView,
    TrackerPDPCarousel,
    AffirmPackagePrice,
    PackageIncludedCarousel,
    QuickwrapSelectors,
    PackageUpsells,
    PackageWarranties,
    FCImage,
    Video,
    FCButton,
  },
  setup(_, { emit }) {
    const packageData = inject<IPackageProperties>(PACKAGE_DATA);

    const {
      $options, $store, $ecommAnalytics, $analytics, $smoothScroll,

      // @ts-ignore
    } = useNuxtApp();

    onMounted(() => {
      $store.commit(storeMutations.SET_PACKAGE_PAGE_TOTAL, packageData.value?.price);
      const shopifyProduct = $store.getters.productsCollection?.find(p => p.id === packageData.value.id);
      $ecommAnalytics.view_item([{
        item_id: shopifyProduct.id,
        item_name: shopifyProduct.title,
        price: shopifyProduct.variants[0].price,
        item_category: shopifyProduct.productType,
      }]);
      $analytics.productViewed(packageData.value);
    });

    function formatPrice(price: number) {
      return $options.filters.currency2(price);
    }

    const selectedSize = ref<string>(packageData.value?.variants?.[0]?.id || '');
    const updateSizeSelected = (value: string) => { selectedSize.value = value; };

    const selectedItems: Ref<ISelectedUpsellProperties[]> = ref([]);
    const selectedWarranty: Ref<ISelectedUpsellProperties | null> = ref(null);

    const handleAddWarranty = (warrantyProduct: ISelectedUpsellProperties) => { selectedWarranty.value = warrantyProduct; };
    const handleSelectedUpsells = (selectedItemsInUpsell: ISelectedUpsellProperties[]) => { selectedItems.value = selectedItemsInUpsell; };
    const handleRemoveWarranty = () => { selectedWarranty.value = null; };

    const totalPrice: Ref<number> = ref(packageData.value?.price ?? 0);
    watch([selectedItems, selectedWarranty], ([newSelectedItems, newSelectedWarranty]) => {
      let total = packageData.value?.price ?? 0;

      newSelectedItems.forEach((item) => {
        total += item.price;
      });

      if (newSelectedWarranty) {
        total += newSelectedWarranty.price;
      }

      totalPrice.value = total;

      emit('updateTotalPrice', totalPrice.value);
    }, { deep: true });

    const loading = ref(false);
    const { insertItemToCart } = useCartContext();
    async function addToCart() {
      loading.value = true;
      await insertItemToCart(selectedSize.value, { delay: true });
      const itemsToInsert = [
        ...(selectedWarranty.value ? [selectedWarranty.value] : []),
        ...selectedItems.value,
      ];

      await Promise.all(itemsToInsert.map(item => insertItemToCart(item.selectedId, {
        type: item.productType === 'warranty' ? 'warranty' : 'equip',
      })));

      window.$nuxt.$router.push('/shop/checkout');
      loading.value = false;
    }

    function scrollToReviews() {
      $smoothScroll({
        scrollTo: document.getElementById('customerReviews'),
        offset: -100,
        updateHistory: false,
      });
    }

    function isImage(media) {
      return media.src;
    }

    // need to remove the second image from the assets array
    // TODO maybe have a lifeStyle primary image field in Storyblok for the image we need to remove below
    const packageAssets = computed(() => packageData.value?.assets?.filter((asset, index) => index !== 1));

    const { reloadAffirmForPriceUpdate } = useAffirm();
    const affirmButton = ref<HTMLElement | null>(null);
    reloadAffirmForPriceUpdate({ price: totalPrice, elementRef: affirmButton });

    return {
      packageData,
      formatPrice,
      updateSizeSelected,
      handleAddWarranty,
      handleRemoveWarranty,
      selectedItems,
      handleSelectedUpsells,
      totalPrice,
      selectedSize,
      addToCart,
      loading,
      scrollToReviews,
      isImage,
      packageAssets,
      affirmButton,
    };
  },
});
