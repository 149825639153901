
import { defineComponent } from '@vue/composition-api';
import FCImage from '~/components/FCImage.vue';
import { useModal } from '~/composables/useModal';

const trainerData = [
  {
    name: 'Jess Evans',
    image: {
      src: 'https://a.storyblok.com/f/152150/624x624/b0dfc64e02/fc-trainer-jess-evans-001.png',
      alt: 'Jess Evans',
    },
    video: {
      _uid: 'fe7ea043-9f69-41ed-b507-cd47e55e9ee5',
      codeId: 'Video-Member-Review-Jenn',
      component: 'Video',
      isOverlay: false,
      playsAudio: false,
      hasControls: true,
      hasPlayIcon: false,
      mp4VideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-unlimited-content-conditioning-001.mp4',
      webmVideoUrl: '',
      firstFrameImage: {
        id: 6837538,
        alt: '',
        name: '',
        focus: '',
        title: '',
        filename: 'https://a.storyblok.com/f/152150/1202x676/f6b29b0db0/fc-jess-first-frame-trainer-preview-001.png',
        copyright: '',
        fieldtype: 'asset',
        is_external_url: false,
      },
      mp4MobileVideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-unlimited-content-conditioning-mobile-001.mp4',
      webmMobileVideoUrl: '',
      mobileVideoPlayback: 'auto',
      tabletUpVideoPlayback: 'auto',
    },
  },
  {
    name: 'PJ Shirdan',
    image: {
      src: 'https://a.storyblok.com/f/152150/624x624/01b9617945/fc-trainer-pj-shirdan-001.png',
      alt: 'PJ Shirdan',
    },
    video: {
      _uid: 'fe7ea043-9f69-41ed-b507-cd47e55e9ee5',
      codeId: 'Video-Member-Review-Jenn',
      component: 'Video',
      isOverlay: false,
      playsAudio: false,
      hasControls: true,
      hasPlayIcon: false,
      mp4VideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-unlimited-content-boxing-open-level-001.mp4',
      webmVideoUrl: '',
      firstFrameImage: {
        id: 6837538,
        alt: '',
        name: '',
        focus: '',
        title: '',
        filename: 'https://a.storyblok.com/f/152150/1278x720/8b2bfb7915/fc-pj-first-frame-trainer-preview-001.png',
        copyright: '',
        fieldtype: 'asset',
        is_external_url: false,
      },
      mp4MobileVideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-unlimited-content-boxing-open-level-mobile-001.mp4',
      webmMobileVideoUrl: '',
      mobileVideoPlayback: 'auto',
      tabletUpVideoPlayback: 'auto',
    },
  },
  {
    name: 'Charlotte Craig',
    image: {
      src: 'https://a.storyblok.com/f/152150/624x624/a050ea4e01/fc-trainer-charlotte-craig-001.png',
      alt: 'Charlotte Craig',
    },
    video: {
      _uid: 'fe7ea043-9f69-41ed-b507-cd47e55e9ee5',
      codeId: 'Video-Member-Review-Jenn',
      component: 'Video',
      isOverlay: false,
      playsAudio: false,
      hasControls: true,
      hasPlayIcon: false,
      mp4VideoUrl: 'https://cdn-b.joinfightcamp.com/videos/charlotte_video.mp4',
      webmVideoUrl: '',
      firstFrameImage: {
        id: 6837538,
        alt: '',
        name: '',
        focus: '',
        title: '',
        filename: 'https://a.storyblok.com/f/152150/2994x1626/8cac2efb28/fc-charlotte-first-frame-001.png',
        copyright: '',
        fieldtype: 'asset',
        is_external_url: false,
      },
      mp4MobileVideoUrl: 'https://cdn-b.joinfightcamp.com/videos/charlotte_video.mp4',
      webmMobileVideoUrl: '',
      mobileVideoPlayback: 'auto',
      tabletUpVideoPlayback: 'auto',
    },
  },
  {
    name: 'Aaron Swenson',
    image: {
      src: 'https://a.storyblok.com/f/152150/624x624/1a04cf55c5/fc-trainer-aaron-swenson-001.png',
      alt: 'Aaron Swenson',
    },
    video: {
      _uid: 'fe7ea043-9f69-41ed-b507-cd47e55e9ee5',
      codeId: 'Video-Member-Review-Jenn',
      component: 'Video',
      isOverlay: false,
      playsAudio: false,
      hasControls: true,
      hasPlayIcon: false,
      mp4VideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-unlimited-content-shadow-001.mp4',
      webmVideoUrl: '',
      firstFrameImage: {
        id: 6837538,
        alt: '',
        name: '',
        focus: '',
        title: '',
        filename: 'https://a.storyblok.com/f/152150/1250x676/b3e3c230d5/fc-aaron-first-frame-trainer-preview-001.png',
        copyright: '',
        fieldtype: 'asset',
        is_external_url: false,
      },
      mp4MobileVideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-unlimited-content-shadow-mobile-001.mp4',
      webmMobileVideoUrl: '',
      mobileVideoPlayback: 'auto',
      tabletUpVideoPlayback: 'auto',
    },
  },
  {
    name: 'Tommy Duquette',
    image: {
      src: 'https://a.storyblok.com/f/152150/624x624/52160f3690/fc-trainer-tommy-duquette-001.png',
      alt: 'Tommy Duquette',
    },
    video: {
      _uid: 'fe7ea043-9f69-41ed-b507-cd47e55e9ee5',
      codeId: 'Video-Member-Review-Jenn',
      component: 'Video',
      isOverlay: false,
      playsAudio: false,
      hasControls: true,
      hasPlayIcon: false,
      mp4VideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-unlimited-content-boxing-001.mp4',
      webmVideoUrl: '',
      firstFrameImage: {
        id: 6837538,
        alt: '',
        name: '',
        focus: '',
        title: '',
        filename: 'https://a.storyblok.com/f/152150/1202x676/2f033a1d7a/fc-tommy-first-frame-trainer-preview-001.png',
        copyright: '',
        fieldtype: 'asset',
        is_external_url: false,
      },
      mp4MobileVideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-unlimited-content-boxing-mobile-001.mp4',
      webmMobileVideoUrl: '',
      mobileVideoPlayback: 'auto',
      tabletUpVideoPlayback: 'auto',
    },
  },
  {
    name: 'Flomaster',
    image: {
      src: 'https://a.storyblok.com/f/152150/624x624/57ab4df5e7/fc-trainer-flow-master-001.png',
      alt: 'Flow Master',
    },
    video: {
      _uid: 'fe7ea043-9f69-41ed-b507-cd47e55e9ee5',
      codeId: 'Video-Member-Review-Jenn',
      component: 'Video',
      isOverlay: false,
      playsAudio: false,
      hasControls: true,
      hasPlayIcon: false,
      mp4VideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-unlimited-content-kickboxing-001.mp4',
      webmVideoUrl: '',
      firstFrameImage: {
        id: 6837538,
        alt: '',
        name: '',
        focus: '',
        title: '',
        filename: 'https://a.storyblok.com/f/152150/1920x1080/13566af73c/fc-flo-first-frame-trainer-preview-001.png',
        copyright: '',
        fieldtype: 'asset',
        is_external_url: false,
      },
      mp4MobileVideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-unlimited-content-kickboxing-mobile-001.mp4',
      webmMobileVideoUrl: '',
      mobileVideoPlayback: 'auto',
      tabletUpVideoPlayback: 'auto',
    },
  },
  {
    name: 'Selina Flores',
    image: {
      src: 'https://a.storyblok.com/f/152150/624x624/3654e331e7/fc-trainer-selina-flores-001.png',
      alt: 'Selina Flores',
    },
    video: {
      _uid: 'fe7ea043-9f69-41ed-b507-cd47e55e9ee5',
      codeId: 'Video-Member-Review-Jenn',
      component: 'Video',
      isOverlay: false,
      playsAudio: false,
      hasControls: true,
      hasPlayIcon: false,
      mp4VideoUrl: 'https://cdn-b.joinfightcamp.com/videos/Selena_video.mp4',
      webmVideoUrl: '',
      firstFrameImage: {
        id: 6837538,
        alt: '',
        name: '',
        focus: '',
        title: '',
        filename: 'https://a.storyblok.com/f/152150/2998x1684/e8648ff899/fc-selena-first-frame-001.png',
        copyright: '',
        fieldtype: 'asset',
        is_external_url: false,
      },
      mp4MobileVideoUrl: 'https://cdn-b.joinfightcamp.com/videos/Selena_video.mp4',
      webmMobileVideoUrl: '',
      mobileVideoPlayback: 'auto',
      tabletUpVideoPlayback: 'auto',
    },
  },
];

export default defineComponent({
  name: 'TrainersPreview',
  components: {
    FCImage,
  },
  setup(_, context) {
    function openVidoModal(video) {
      const width = Math.min(680, Math.floor(window.innerWidth * 0.85));

      const { modalShow } = useModal({
        component: () => import('~/components/storyblok/VideoModal/VideoModal.vue'),
        componentProps: video,
        modalProps: {
          componentName: 'VideoModal',
          width,
          height: Math.floor(width / (16 / 9)),
          reset: true,
          classes: 'modal-border-radius',
        },
        options: {
          emit: context.emit,
          openEvent: {
            name: 'onPause',
            payload: true,
          },
          closeEvent: {
            name: 'onPause',
            payload: false,
          },
        },
      });

      modalShow();
    }

    return {
      trainerData,
      openVidoModal,
    };
  },
});
