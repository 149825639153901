
import { defineComponent, computed } from '@vue/composition-api';
import { isMobileEffect } from '~/effects/matchMedia';
import WhatMakesFCCard from './WhatMakesFCCard.vue';

const cardData = [
  {
    title: 'The Console & Trackers',
    image: {
      src: 'https://a.storyblok.com/f/152150/470x676/936092e2df/fc-trackers-into-quickwrap-pocket-001.png',
      alt: 'A person inserting FightCamp tracker into quickwrap pocket',
    },
    splitViewVideo: {
      firstFrameImageUrl: 'https://a.storyblok.com/f/152150/1920x1080/0d6842a9d0/fc-console-home-first-frame-001.png',
      mp4MobileVideo: 'https://cdn-b.joinfightcamp.com/videos/fc-console-home-video-001.mp4',
      mp4Video: 'https://cdn-b.joinfightcamp.com/videos/fc-console-home-video-001.mp4',
      webmMobileVideo: 'https://cdn-b.joinfightcamp.com/videos/fc-console-home-video-001.webm',
      webmVideo: 'https://cdn-b.joinfightcamp.com/videos/fc-console-home-video-001.webm',
      videoComponent: {
        _uid: 'e473f740-26f4-454d-afe1-108bb14879cd',
        codeId: '',
        component: 'Video',
        isOverlay: false,
        playsAudio: false,
        hasControls: false,
        hasPlayIcon: false,
        hasPauseIcon: false,
        mp4VideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-console-home-video-001.mp4',
        webmVideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-console-home-video-001.webm',
        firstFrameImage: {
          id: 16590149,
          alt: '',
          name: '',
          focus: '',
          title: '',
          source: '',
          filename: 'https://a.storyblok.com/f/152150/1920x1080/0d6842a9d0/fc-console-home-first-frame-001.png',
          copyright: '',
          fieldtype: 'asset',
          meta_data: {},
          is_private: false,
          is_external_url: false,
        },
        mp4MobileVideoUrl: '',
        webmMobileVideoUrl: '',
        mobileVideoPlayback: '',
        tabletUpVideoPlayback: '',
      },
    },
    splitViewList: [
      {
        image: {
          src: 'https://a.storyblok.com/f/152150/672x756/1ffe154fa7/fc-console-trackers-on-table-001.png',
          alt: 'FightCamp console with four trackers on table',
        },
        title: 'A Powerfully Smart Engine',
        description: 'More than a charging dock, the FightCamp Console is the brain behind all of FightCamp’s interactive features. It brings your experience to life. Connect the Console to your TV to stream your workouts on the big screen or use your mobile device. Your Console can support up to four Trackers.',
      },
      {
        image: {
          src: 'https://a.storyblok.com/f/152150/672x756/cc6fa36fd7/fc-person-punching-bag-outside-001.png',
          alt: 'A person punching a FightCamp bag',
        },
        title: 'Every Punch Counts',
        description: 'The FightCamp Trackers connect you to your workout. Slide a Tracker into your quick-wraps to measure your movements in a boxing or kickboxing workout. Each round has a punch count goal for you to hit. Throughout your workout, you’ll see your progress displayed on the screen in real time.',
      },
    ],
  },
  {
    title: 'The App',
    image: {
      src: 'https://a.storyblok.com/f/152150/848x1220/53ae4aed1e/fc-companion-app-001.png',
      alt: 'A person on the FightCamp App picking a workout',
    },
    splitViewImage: {
      src: 'https://a.storyblok.com/f/152150/1744x858/68b7142cc0/fc-companion-app-module-001.png',
      alt: 'A person on the FightCamp App picking a workout',
    },
    splitViewList: [
      {
        image: {
          src: 'https://a.storyblok.com/f/152150/1454x1454/1788314c60/fc-complete-workout-modal-001.png',
          alt: 'Two people working out with FightCamp app',
        },
        title: 'A Complete Workout Library',
        description: 'Jump straight into a workout with our recommendations or choose from more than 1000 workouts in our library — we add new workouts every week. You’ll find boxing, kickboxing, shadowboxing, strength training, core, conditioning, stretching, and recovery classes.',
      },
      {
        video: {
          firstFrameImageUrl: 'https://a.storyblok.com/f/152150/1670x1600/94afbfbc0f/fc-guidance-from-the-start-video-001.png',
          mp4MobileVideo: '',
          mp4Video: 'https://cdn-b.joinfightcamp.com/videos/fc-guidance-from-start-001.mp4 ',
          webmMobileVideo: '',
          webmVideo: 'https://cdn-b.joinfightcamp.com/videos/fc-guidance-from-the-start-001.webm',
          videoComponent: {
            _uid: 'e473f740-26f4-454d-afe1-108bb14879cd',
            codeId: '',
            component: 'Video',
            isOverlay: false,
            playsAudio: false,
            hasControls: false,
            hasPlayIcon: false,
            hasPauseIcon: false,
            mp4VideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-guidance-from-start-001.mp4',
            webmVideoUrl: 'https://cdn-b.joinfightcamp.com/videos/fc-guidance-from-the-start-001.webm',
            firstFrameImage: {
              id: 16590149,
              alt: '',
              name: '',
              focus: '',
              title: '',
              source: '',
              filename: 'https://a.storyblok.com/f/152150/1670x1600/94afbfbc0f/fc-guidance-from-the-start-video-001.png',
              copyright: '',
              fieldtype: 'asset',
              meta_data: {},
              is_private: false,
              is_external_url: false,
            },
            mp4MobileVideoUrl: '',
            webmMobileVideoUrl: '',
            mobileVideoPlayback: '',
            tabletUpVideoPlayback: '',
          },
        },
        title: 'Guidance from the Start',
        description: 'Choose from one of our Programs for a day-by-day workout plan. Plus, use our tutorials to learn the basics from day one. And if you get lost, our supportive community & trainers are always around to help give you some pointers.',
      },
      {
        image: {
          src: 'https://a.storyblok.com/f/152150/672x756/c69d9c38ea/fc-app-stats-preview-001.png',
          alt: 'FightCamp stats preview',
        },
        title: 'See progress over time',
        description: 'During a workout, your Trackers record metrics like punch count, speed, and rounds completed. Your stats are saved in the app, making it easy to track and celebrate your progress.',
      },
    ],
  },
  {
    title: 'The Workouts',
    image: {
      src: 'https://a.storyblok.com/f/152150/848x1220/bbf29dca2b/fc-pj-punching-fightcamp-bag-001.png',
      alt: 'FightCamp trainer PJ punching FightCamp bag',
    },
    splitViewImage: {
      src: 'https://a.storyblok.com/f/152150/1744x858/a1ae9b330a/fc-pj-punching-bag-module-001.png',
      alt: 'FightCamp trainer PJ punching FightCamp bag',
    },
    splitViewList: [
      {
        image: {
          src: 'https://a.storyblok.com/f/152150/672x756/6d3deb918c/fc-app-schedule-001.png',
          alt: 'FightCampt app schedule',
        },
        title: 'Built for Your Schedule',
        description: 'You don’t have time for gym commutes and machine hoggers. Your sweat session needs to be at home and ready when you are. Luckily, with FightCamp, it doesn’t matter if you have 5 minutes or 45 to spare. Our no-schedules-required, on-demand workouts make it your time to shine at any time.',
      },
      {
        image: {
          src: 'https://a.storyblok.com/f/152150/672x756/3d8275c74c/fc-make-it-your-own-modal-001.png',
          alt: 'FightCampt trainer PJ working out alongside person',
        },
        title: 'Make Them Your Own',
        description: 'Boxing & kickboxing might look a little intense. That’s because they are a little intense. But, it’s nothing you can’t handle. In fact, it’s probably the exact level of intensity you’ve been craving all along. With FightCamp, you can dial up or dial down the intensity to match your needs.',
      },
      {
        image: {
          src: 'https://a.storyblok.com/f/152150/672x756/b519a83239/fc-beginner-to-big-hitter-001.png',
          alt: 'FightCampt app latest achievements',
        },
        title: 'Go from a Beginner to a Big Hitter',
        description: "Plenty of tutorials are available at your fingertips. Follow along with programs as our trainers provide clear, step-by-step instructions. Over time, you'll master techniques safely and confidently.",
      },
    ],
  },
];

export default defineComponent({
  name: 'WhatMakesFC',
  components: {
    WhatMakesFCCard,
  },
  setup() {
    const isMobile = isMobileEffect();
    const slideList = computed(() => [...cardData, ...cardData]);

    return { slideList, isMobile, cardData };
  },
});
