
import { defineComponent, PropType } from '@vue/composition-api';
import { isMobileEffect } from '~/effects/matchMedia';
import FCImage from '~/components/FCImage.vue';
import Video from '~/components/storyblok/Video/Video.vue';

export default defineComponent({
  name: 'PdpCarousel',
  components: {
    FCImage,
    Video,
  },
  props: {
    assets: {
      type: Array as PropType<{ src: string; alt: string }[]>,
      required: true,
      default: () => [
        {
          src: 'https://a.storyblok.com/f/152150/375x375/44e2537646/fc-website-v2-shop-test-image-004.png',
          alt: 'Placeholder image',
        },
      ],
    },
  },
  setup() {
    function isImage(media: any) {
      return media?.src;
    }
    const styles = {
      '--swiper-scrollbar-drag-bg-color': '#0f1e3c',
      '--swiper-scrollbar-sides-offset': '-1%',
    };
    const isMobile = isMobileEffect();
    return {
      isImage,
      styles,
      isMobile,
    };
  },
});
