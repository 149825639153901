
import { computed, defineComponent } from '@vue/composition-api';
import NavigationCard from './NavigationCard.vue';

export default defineComponent({
  name: 'NavigationCardContainer',
  components: {
    NavigationCard,
  },
  setup() {
    const navCardData = computed(() => [
      {
        img: {
          src: 'https://a.storyblok.com/f/152150/1296x901/8c7bf515fb/fc-person-workingout-link-001.png',
          alt: 'Person working out in a home gym using FightCamp equipment',
        },
        cta: 'Shop Package',
        ctaLink: 'shop/products/fightcamp',
      },
      {
        img: {
          src: 'https://a.storyblok.com/f/152150/1291x900/ba4313604a/fc-boxing-bag-link-001.png',
          alt: 'Boxing bag in home gym',
        },
        cta: 'What is FightCamp?',
        ctaLink: 'what-is-fightcamp',
      },
    ]);

    return {
      navCardData,
    };
  },
});
