
import { defineComponent } from '@vue/composition-api';
import FCImage from '~/components/FCImage.vue';
import { FCButton } from '@fc/angie-ui';
import { useModal } from '~/composables/useModal';

export default defineComponent({
  name: 'ExploreFCCard',
  components: {
    FCImage,
    FCButton,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { modalShow } = useModal({
      component: () => import('../ExploreFCModal.vue'),
      componentProps: { product: props.product },
      modalProps: {
        componentName: 'ExploreFCModal',
        width: process.client ? Math.min(400, Math.floor(window?.innerWidth * 0.85)) : 'auto',
        height: process.client ? Math.min(800, Math.floor(window?.innerHeight * 0.90)) : 'auto',
        reset: true,
        classes: 'modal-mobile-full-screen',
      },
    });
    function openModal() { modalShow(); }

    return {
      openModal,
    };
  },
});
