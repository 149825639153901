
import {
  computed, defineComponent, inject, ref, toRef,
} from '@vue/composition-api';
import { useAffirm } from '~/composables/useAffirm';
import { IPackageProperties } from '~/types/fightcamp';
import { PACKAGE_DATA } from '~/constants/storyblok';
import { AffirmLogo } from '@fc/angie-ui';

export default defineComponent({
  name: 'AffirmPayLater',
  components: {
    AffirmLogo,
  },
  props: {
    totalPrice: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const packageData = inject<IPackageProperties>(PACKAGE_DATA);
    const packageName = computed(() => packageData.value?.productName || '');

    const price = toRef(props, 'totalPrice');
    const affirmButton = ref<HTMLElement | null>(null);
    const { reloadAffirmForPriceUpdate } = useAffirm();
    reloadAffirmForPriceUpdate({ price, elementRef: affirmButton });

    return {
      price,
      packageName,
      affirmButton,
    };
  },
});
