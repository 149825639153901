
import {
  defineComponent, ref, watch, PropType, computed,
} from '@vue/composition-api';
import { IPackageProperties } from '~/types/fightcamp';
import PdpSectionHeader from './PdpSectionHeader.vue';

interface TransformedWarranty {
  productName: string;
  price: number;
  description: string;
  id: string;
  uid: string;
}

export default defineComponent({
  name: 'PackageWarranties',
  components: {
    PdpSectionHeader,
  },
  props: {
    warranties: {
      type: Array as PropType<IPackageProperties[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    /*
      transform data since we need the variant id and uid for the selected value.
      warranty items will only have one variant
    */
    const transformedWarranties = computed<TransformedWarranty[]>(() => props.warranties?.map((warranty) => ({
      productName: warranty?.productName || '',
      price: warranty?.price || 0,
      description: warranty?.description || '',
      id: warranty?.variants?.[0]?.id || '',
      uid: warranty?.variants?.[0]?.uid || '',
    })));

    const selectedValue = ref('');

    watch(transformedWarranties, newWarranties => {
      if (newWarranties.length > 0) {
        selectedValue.value = newWarranties[0].id;
      }
    }, { immediate: true });

    watch(selectedValue, () => {
      const selectedWarranty = props.warranties?.find((warranty) => warranty?.variants[0]?.id === selectedValue.value);

      /*
        if the selected warranty is a limited warranty, do not add it to the selected items since it is included in package
      */
      if (selectedWarranty?.tags.includes('limited-warranty')) {
        emit('removeWarranty');
        return;
      }

      // adds non limited warranty to selected items
      emit('addWarranty', { ...selectedWarranty, selectedId: selectedWarranty?.variants[0].id });
    });

    return {
      selectedValue,
      transformedWarranties,
    };
  },
});
