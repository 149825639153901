
import { CMSService } from '~/services/storyBlok/cmsService';
import TrackersATF from '~/components/v2/pdps/TrackersATF.vue';
import ExploreFC from '~/components/v2/pdps/ExploreFC.vue';
import TestimonialVideos from '~/components/v2/home/TestimonialVideos.vue';
import ReviewsGrid from '~/components/storyblok/ReviewsGrid/ReviewsGrid.vue';
import WhatMakesFC from '~/components/v2/WhatMakesFC.vue';
import TrainersPreview from '~/components/v2/TrainersPreview.vue';
import AffirmPayLater from '~/components/v2/pdps/AffirmPayLater.vue';
import NavigationCardContainer from '~/components/v2/NavigationCardContainer.vue';

export default {
  name: 'PackagePage',
  components: {
    TrackersATF,
    ExploreFC,
    TestimonialVideos,
    ReviewsGrid,
    WhatMakesFC,
    TrainersPreview,
    AffirmPayLater,
    NavigationCardContainer,
  },
  mounted() {
    if (window.affirm && window.affirm.ui) {
      window.affirm.ui.ready(() => {
        window.affirm.ui.refresh();
      });
    }
  },
  data() {
    return {
      reviews: [],
      totalPrice: 0,
    };
  },
  methods: {
    updateTotal(price) {
      this.totalPrice = price;
    },
  },
  async fetch() {
    try {
      const storyBlok = new CMSService({ cmsService: this.$storyapi });
      const reviews = await storyBlok.getAll('user-reviews');

      this.reviews = reviews;
    } catch (e) {
      console.log(e);
    }
  },
};
