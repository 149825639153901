
import { defineComponent, PropType } from '@vue/composition-api';
import { SizeSelectors } from '@fc/angie-ui';
import { IProductVariant } from '~/types/fightcamp';
import PdpSectionHeader from './PdpSectionHeader.vue';

export default defineComponent({
  name: 'QuickwrapSelectors',
  components: {
    SizeSelectors,
    PdpSectionHeader,
  },
  props: {
    // TODO type
    productSelects: {
      type: Array as PropType<IProductVariant[]>,
      required: true,
      default: () => {},
    },
  },
  setup(_, context) {
    const updateSizeValue = (value: string) => {
      context.emit('selectedSize', value);
    };

    return {
      updateSizeValue,
    };
  },
});
