
import { computed, defineComponent, inject } from '@vue/composition-api';
import { useModal } from '~/composables/useModal';
import { isTabletUpEffect } from '~/effects/matchMedia';
import { IPackageProperties } from '~/types/fightcamp';
import { PACKAGE_DATA } from '~/constants/storyblok';
import ExploreFCCard from './ExploreFCCard.vue';

const slideData = [
  {
    title: 'Console',
    id: 'console',
    description: 'The game changing tech that brings you the full FightCamp experience. The console serves as the brains that connects your app to your TV and your trackers charged and ready. The trackers are the key to track every punch of your workout, keep you engaged, and meet your fitness goals.',
    specifications: '8 5/8" x 3 7/8" x 1 1/4"',
    image: {
      src: 'https://a.storyblok.com/f/152150/528x372/242d8a3a2f/fc-explore-console-001.png',
      alt: 'FightCamp console with four trackers',
    },
    modalData: {
      title: 'What is the Console?',
      description: 'The FightCamp Console is the hub for all of FightCamp’s interactive features. It brings your experience to life. Connect the Console to your TV to stream your workouts on the big screen or use your mobile device. Your Console can support up to two pairs of Trackers.',
      image: {
        src: 'https://a.storyblok.com/f/152150/528x372/242d8a3a2f/fc-explore-console-001.png',
        alt: 'FightCamp console with four trackers',
      },
    },
  },
  {
    title: 'Trackers',
    id: 'trackers',
    description: 'The FightCamp Trackers connect you to your workout. Slide a Tracker into your quick-wraps to measure your movements in a boxing or kickboxing workout. Each round has a punch count goal for you to hit. Throughout your workout, you’ll see your progress displayed on the screen in real time.',
    image: {
      src: 'https://a.storyblok.com/f/152150/528x372/d58dd79b6e/fc-explore-trackers-001.png',
      alt: 'FightCamp tracker being placed in console',
    },
    modalData: {
      title: 'What are the Trackers?',
      description: 'The FightCamp Trackers connect you to your workout. Slide a Tracker into your quick-wraps to measure your movements in a boxing or kickboxing workout. Each round has a punch count goal for you to hit. Throughout your workout, you’ll see your progress displayed on the screen in real time.',
      image: {
        src: 'https://a.storyblok.com/f/152150/528x372/d58dd79b6e/fc-explore-trackers-001.png',
        alt: 'FightCamp tracker being placed in console',
      },
    },
  },
  {
    title: 'Free-Standing Boxing Bag',
    id: 'boxing-bag',
    description: 'The FightCamp Free-Standing Boxing Bag is the best free-standing bag on the market. It’s beautifully designed to look great in your home. It fits in a just about any space (it’s only 2ft wide and 5ft 7in tall). It holds about 250 lbs of water or 350 lbs of sand (use sand for extra stability). Pair the FightCamp Boxing Bag with the FightCamp Bag Ring to ensure that the boxing bag stays fully in place — even after the toughest workout.',
    image: {
      src: 'https://a.storyblok.com/f/152150/528x372/334449991d/fc-explore-bag-001.png',
      alt: 'FightCamp boxing bag in home gym',
    },
    modalData: {
      title: 'What is the Free-Standing Boxing Bag?',
      description: 'The FightCamp free-standing bag is engineered for maximum shock absorption with a two-part stem design that’s easy to set up and tough to knock down. (Seriously, even Mike Tyson couldn’t topple this bag!) Feel the thrill of giving it your all. No need to pull your punches, your bag isn’t going anywhere. It’s your ultimate at-home boxing partner—always ready for a round and never backing down.',
      image: {
        src: 'https://a.storyblok.com/f/152150/528x372/334449991d/fc-explore-bag-001.png',
        alt: 'FightCamp boxing bag in home gym',
      },
    },
  },
  {
    title: 'Membership',
    id: 'membership',
    description: "Our membership includes access to boxing, kickboxing, strength, conditioning, warmups, core, stretch, recovery, drill, and even shadowboxing and shadow kickboxing workouts. With new workouts added each week, you'll always have fresh content to choose from.",
    image: {
      src: 'https://a.storyblok.com/f/152150/528x372/02319b6606/fc-explore-membership-001.png',
      alt: 'Jess the trainer working out on tv',
    },
    modalData: {
      title: 'What is the Membership?',
      description: 'The FightCamp Membership gives you access to complete and well-rounded boxing, kickboxing & strength training, all in one place. Jump straight into a workout with our recommendations or choose from more than 1000 workouts in our library — we add new workouts every week. You’ll find boxing, kickboxing, shadowboxing, strength training, core, conditioning, stretching, and recovery classes packed inside our app.',
      image: {
        src: 'https://a.storyblok.com/f/152150/528x372/02319b6606/fc-explore-membership-001.png',
        alt: 'Jess the trainer working out on tv',
      },
    },
  },
];

export default defineComponent({
  name: 'ExploreFC',
  components: {
    ExploreFCCard,
  },
  setup() {
    const packageData = inject<IPackageProperties>(PACKAGE_DATA);
    const hasBag = computed(() => packageData.value?.includesBag);

    function openModal(slide: any) {
      const { modalShow } = useModal({
        component: () => import('../ExploreFCModal.vue'),
        componentProps: { product: slide },
        modalProps: {
          componentName: 'ExploreFCModal',
          width: Math.min(400, Math.floor(window?.innerWidth * 0.85)),
          height: Math.min(800, Math.floor(window?.innerHeight * 0.90)),
          reset: true,
          classes: 'modal-mobile-full-screen',
        },
      });

      modalShow();
    }

    const productSlides = computed(() => {
      if (!hasBag.value) {
        return slideData.filter((slide) => slide.id !== 'boxing-bag');
      }
      return slideData;
    });

    const isTabletUp = isTabletUpEffect();

    return {
      productSlides,
      openModal,
      isTabletUp,
    };
  },
});
